import Header from "../components/header/header";
import React from "react";
import Footer from "../components/footer/footer";
import Fleet from "../components/fleet/fleet";
import {Helmet} from "react-helmet";

import SocialImage from '../images/social/LogoNeu.jpg';

export default () => (
    <>
        <Helmet htmlAttributes={{"lang": "de"}}>
            {/*Primary Meta Tags*/}
            <title>Kronschl&auml;ger Reisen — Busflotte</title>
            <meta name="title" content="Kronschl&auml;ger Reisen — Busflotte"/>
            <meta name="description" content="Ob allein, mit Familie, mit Freunden, ihrer Firma oder ihrem Verein - wir haben das passende Angebot f&uuml;r jede Gelegenheit in unserer Flotte. Mehr als 10 modern ausgestattete Fahrzeuge..."/>

            {/*Open Graph / Facebook */}
            <meta property="og:type" content="website"/>
            <meta property="og:url" content="https://www.kronschlaeger-reisen.at/"/>
            <meta property="og:title" content="Kronschl&auml;ger Reisen — Busflotte"/>
            <meta property="og:description" content="Ob allein, mit Familie, mit Freunden, ihrer Firma oder ihrem Verein - wir haben das passende Angebot f&uuml;r jede Gelegenheit in unserer Flotte. Mehr als 10 modern ausgestattete Fahrzeuge..."/>
            <meta property="og:image" content={`https://www.kronschlaeger-reisen.at${SocialImage}`}/>

            {/* Google Bot */}
            <meta name="robots" content="index,follow"/>
        </Helmet>
        <Header/>
        <Fleet/>
        <Footer/>
    </>
);
