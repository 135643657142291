import React from "react";
import AwesomeSlider from 'react-awesome-slider';
import AwesomeSliderStyles from 'react-awesome-slider/src/styles';
import {graphql, useStaticQuery} from "gatsby";
import Img from "gatsby-image";

import * as styles from './fleet.module.css';

import bus50_1 from './media/50-sitzer-1.jpg';
import bus50_2 from './media/50-sitzer-2.jpg';
import bus50_3 from './media/50-sitzer-3.jpg';

import bus30_1 from './media/30-sitzer-1.jpg';
import bus30_2 from './media/30-sitzer-2.jpg';

import bus20_1 from './media/20-sitzer-1.jpg';
import bus20_2 from './media/20-sitzer-2.jpg';
import bus20_3 from './media/20-sitzer-3.jpg';
import bus20_4 from './media/20-sitzer-4.jpg';
import bus20_5 from './media/20-sitzer-5.jpg';

import bus13_electro from './media/13-9-sitzer-elektro.jpg';
import bus13_1 from './media/13-9-sitzer-1.jpg';
import bus13_2 from './media/13-9-sitzer-2.jpg';
import bmw_1 from './media/bmw-1.jpg';
import bmw_2 from './media/bmw-2.jpg';
import bmw_3 from './media/bmw-3.jpg';


export default function Fleet() {
    const data = useStaticQuery(graphql`
        query {
            allFile(filter: {relativeDirectory: {eq: "fleet"}}) {
                nodes {
                    childImageSharp {
                        id
                        fluid {
                            ...GatsbyImageSharpFluid_withWebp
                            originalImg
                        }
                    }
                }
            }
        }
    `);

    return (
        <div className={"container"}>
            <div className={"row"}>
                <div className={"col-sm-12"}>
                    <div className={styles.sectionHeaderContainer}>
                        <h2>Unsere Reise&shy;busse</h2>
                        <p>
                            Ob allein, mit Famile, mit Freunden, ihrer Firma oder ihrem Verein: Wir haben das passende
                            Angebot f&uuml;r jede Gelegenheit in unserer Flotte. Mehr als 10 modern ausgestattete
                            Fahrzeuge in verschiedenen Gr&ouml;&szlig;en warten auf Sie.
                        </p>
                    </div>
                </div>
            </div>
            <div className={"row " + styles.busRow}>
                <div className={"col-lg-4 offset-lg-1 col-md-12 " + styles.busTextAreaLeft}>
                    <h5 className={styles.busSubheading}>50 Sitzer SETRA S 515 HD</h5>
                    <h3 className={styles.busHeading}>Einsteigen und genie&szlig;en!</h3>
                    <p className={styles.busDescription}>Moderner Reisebus mit top Sitzabstand,
                        WC &amp; Bordk&uuml;che</p>
                    <div className={styles.busFeaturesLeft}>
                        <ul>
                            <li>Alle Doppelsitze mit<br/>230V Steckdose</li>
                        </ul>
                    </div>
                </div>
                <div className={"col-lg-7 col-md-12"}>
                    <AwesomeSlider cssModule={AwesomeSliderStyles} bullets={false}>
                        <div data-src={bus50_1}/>
                        <div data-src={bus50_2}/>
                        <div data-src={bus50_3}/>
                    </AwesomeSlider>
                </div>
            </div>

            <div className={"row " + styles.busRow}>
                <div className={"col-lg-7 col-md-12 order-lg-1 order-2"}>
                    <AwesomeSlider cssModule={AwesomeSliderStyles} bullets={false}>
                        <div data-src={bus30_1}/>
                        <div data-src={bus30_2}/>
                    </AwesomeSlider>
                </div>
                <div className={"col-lg-4 col-md-12 order-lg-2 order-1 " + styles.busTextAreaRight}>
                    <h5 className={styles.busSubheading}>30 Sitzer Mercedes MARATON</h5>
                    <h3 className={styles.busHeading}>F&uuml;r Kleingruppen bis 30 Personen</h3>
                    {/*<p>TODO Kurze Beschreibung des Busses</p>*/}
                    <div className={styles.busFeaturesRight}>
                        <ul>
                            <li>Komfortabler Sitzabstand</li>
                            <li>Sitze zum seitlich verstellen und Schlafbestuhlung</li>
                            <li>Kaffeemaschine an Board</li>
                            <li>Klimaanlage mit D&uuml;senbel&uuml;ftung</li>
                        </ul>
                    </div>
                </div>
            </div>


            <div className={"row " + styles.busRow}>
                <div className={"col-lg-4 offset-lg-1 col-md-12 " + styles.busTextAreaLeft}>
                    <h5 className={styles.busSubheading}>20 Sitzer Mercedes Sprinter</h5>
                    <h3 className={styles.busHeading}>Der kleine Luxus</h3>
                    {/*<p className={styles.busDescription}>Moderner Reisebus mit top Sitzabstand, WC &amp; Bordk&uuml;che</p>*/}
                    <div className={styles.busFeaturesLeft}>
                        <ul>
                            <li>mit Panoramascheiben</li>
                            <li>Luxusbestuhlung</li>
                            <li>Bild&uuml;bertragug von Handy<br/>oder Laptop auf Bordbildschirm<br/>(z.B. f&uuml;r
                                Produktschulungen<br/>w&auml;hrend der Fahrt)
                            </li>
                        </ul>
                    </div>
                </div>
                <div className={"col-lg-7 col-md-12"}>
                    <AwesomeSlider cssModule={AwesomeSliderStyles} bullets={false}>
                        <div data-src={bus20_1}/>
                        <div data-src={bus20_2}/>
                        <div data-src={bus20_4}/>
                        <div data-src={bus20_5}/>
                        <div data-src={bus20_3}/>
                    </AwesomeSlider>
                </div>
            </div>

            <div className={"row " + styles.busRow}>
                <div className={"col-lg-7 col-md-12 order-lg-1 order-2"}>
                    <AwesomeSlider cssModule={AwesomeSliderStyles} bullets={false}>
                        <div data-src={bus13_electro}/>
                        <div data-src={bus13_1}/>
                        <div data-src={bus13_2}/>
                    </AwesomeSlider>
                </div>
                <div className={"col-lg-4 col-md-12 order-lg-2 order-1 " + styles.busTextAreaRight}>
                    <h5 className={styles.busSubheading}>9 &amp; 13 Sitzer</h5>
                    <h3 className={styles.busHeading}>Praktisch f&uuml;r Nah &amp; Fern</h3>
                    {/*<p>TODO Kurze Beschreibung des Busses</p>*/}
                    <div className={styles.busFeaturesRight}>
                        <ul>
                            <li>9 Sitzer auch als Elektrobus<br />- der Umwelt zuliebe</li>
                            <li>Komfortable Bestuhlung</li>
                            <li>Klimaanlage &amp; Heizung<br/>extra für Fahrg&auml;ste</li>
                        </ul>
                    </div>
                </div>
            </div>

            <div className={"row"}>
                <div className={"col-sm-12"}>
                    <div className={styles.sectionHeaderContainer}>
                        <h2>Komfort&shy;fahrzeug f&uuml;r Gesch&auml;fts&shy;partner</h2>
                        <p>
                            Reisen Sie top ausgestattet mit maximalem Konfort! Verw&ouml;hnen Sie sich und Ihre
                            Gesch&auml;ftspartner.
                        </p>
                    </div>
                </div>
            </div>

            <div className={"row " + styles.busRow}>
                <div className={"col-lg-4 offset-lg-1 col-md-12 " + styles.busTextAreaLeft}>
                    <h5 className={styles.busSubheading}>BMW 5er GT</h5>
                    <h3 className={styles.busHeading}>Gepflegtes Reisen in der Business Class</h3>
                    {/*<p className={styles.busDescription}>Kurze Beschreibung TODO</p>*/}
                    <div className={styles.busFeaturesLeft}>
                        <ul>
                            <li>Flughafentransfer</li>
                            <li>Gesch&auml;ftsreisende</li>
                            <li>Komfortabel Reisen</li>
                        </ul>
                    </div>
                </div>
                <div className={"col-lg-7 col-md-12"}>
                    <AwesomeSlider cssModule={AwesomeSliderStyles} bullets={false}>
                        <div data-src={bmw_1}/>
                        <div data-src={bmw_2}/>
                        <div data-src={bmw_3}/>
                    </AwesomeSlider>
                </div>
            </div>
            <div className={"row"}>
                <div className={"col-sm-12"}>
                    <div className={styles.sectionHeaderContainer}>
                        <h2>Kranken&shy;transporte</h2>
                        <p>
                            Wir sind gerne auch f&uuml;r Sie da, wenn der Schuh dr&uuml;ckt. Gerade dann braucht man
                            Mobilit&auml;t in entspannter Atmosph&auml;re.
                        </p>
                    </div>
                </div>
            </div>
            <div className={"row " + styles.busRow}>
                <div className={"col-lg-4 offset-lg-1 col-md-12 " + styles.busTextAreaLeft}>
                    <h5 className={styles.busSubheading}>Opel Crossland</h5>
                    <h3 className={styles.busHeading}>Der kleine Helfer</h3>
                    <p className={styles.busDescription}>Abrechnungsf&auml;hig mit</p>
                    <div className={styles.busFeaturesLeft}>
                        <ul>
                            <li>&Ouml;GK (Oberösterreich)</li>
                            <li>Sozialversicherungstr&auml;ger<br/>der Selbständigen<br/>(SVS; ehemalige SVB & SVA)</li>
                            <li>VA &ouml;ffentlich Bediensteter<br/>(BVA &Ouml;B),<br/>Eisenbahnen und Bergbau<br/>(BVA EB)</li>
                            <li>Pensionsversicherungsanstalt</li>
                        </ul>
                    </div>
                </div>
                <div className={"col-lg-7 col-md-12"}>
                    <Img fluid={data.allFile.nodes[0].childImageSharp.fluid}/>
                    {/*<AwesomeSlider cssModule={AwesomeSliderStyles} bullets={false}>*/}
                    {/*    <div data-src={meriva}/>*/}
                    {/*</AwesomeSlider>*/}
                </div>
            </div>
            <div className={"row"}>
                <div className={"col-sm-12"}>
                    <div className={styles.sectionHeaderContainer}>
                        <h2>Noch nicht den passenden gefunden?</h2>
                        <p>Gerne organisieren wir für Sie die passende Transportm&ouml;glichkeit.</p>
                    </div>
                </div>
            </div>
        </div>
    );
}